import React, { useCallback } from 'react';
import { navigate } from '@myci/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useForm, v } from '@ci/forms';
import { Button } from '@ci/atoms';
import { Grid } from '@myci/ui-components';
import { IntlProvider, Message } from '@myci/intl';
import { resetPassword, selectIsFetchingResetPassword } from '@myci/authentication';
import { Buttons } from '@ci/layout';

import m from '../messages';
import { forms } from '../constants';
import { UserNameField } from './UserNameField';

interface ResetPasswordFormValues {
	username: string;
}
export const validate = v.validate<ResetPasswordFormValues>({
	username: [v.required()],
});

export const ResetPasswordForm = () => {
	const dispatch = useDispatch();
	const isSubmitting = useSelector(selectIsFetchingResetPassword);

	const handleResetPasswordClick = useCallback(
		values => {
			dispatch(resetPassword({ ...values, form: forms.resetPassword }));
		},
		[dispatch]
	);
	const form = useForm({
		id: forms.resetPassword,
		onSubmit: handleResetPasswordClick,
		validate,
	});

	return (
		<IntlProvider>
			<Form form={form}>
				<Grid container flexDirection="column" justifyContent="between">
					<Grid row>
						<Grid col={{ xs: 12, md: 6, lg: 5 }}>
							<UserNameField name="username" isDisabled={isSubmitting} />
							<Buttons
								verticalMargin="lg"
								buttons={[
									<Button onClick={() => navigate('/sign-in')} variant="subtle">
										<Message {...m.cancelButton} />
									</Button>,
									<Button variant="primary" type="submit" isDisabled={isSubmitting}>
										<Message {...m.resetButton} />
									</Button>,
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Form>
		</IntlProvider>
	);
};
