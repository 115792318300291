import React from 'react';
import { useInstanceConfig, userNameTypes } from '@myci/instances';
import { Message } from '@myci/intl';
import { TextInputField } from '@ci/forms';
import { PhoneInputField } from '@myci/phone-numbers';
import m from '../messages';

interface UserNameFieldProps {
	isDisabled?: boolean;
	name: string;
}

export const UserNameField = ({ isDisabled = false, name }: UserNameFieldProps) => {
	const { userNameType, defaultPhoneInputCountry } = useInstanceConfig();
	const userNameFieldType = userNameType ?? userNameTypes.email;

	return userNameFieldType === userNameTypes.phoneNumber ? (
		<PhoneInputField
			label={<Message {...m.signInFieldUsername} />}
			name={name}
			defaultCountry={defaultPhoneInputCountry}
			disabled={isDisabled}
			isRequired
		/>
	) : (
		<TextInputField
			isRequired
			label={<Message {...m.signInFieldUsername} />}
			name={name}
			isDisabled={isDisabled}
		/>
	);
};
