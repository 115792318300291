import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import ChangePassword from '../containers/ChangePassword';

const ChangePasswordPath = () => (
	<Router>
		<ChangePassword path="change-password/:token" />
	</Router>
);

export default ChangePasswordPath;
