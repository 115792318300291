import { defineMessages } from 'react-intl';

export default defineMessages({
	cancelButton: {
		id: 'myciAuth.cancelButton',
		defaultMessage: 'Cancel',
		description: 'Label of the cancel button',
	},
	signInFieldPassword: {
		id: 'myciAuth.signInUserField_password',
		defaultMessage: 'Password',
		description: 'Password of security entity',
	},
	signInFieldUsername: {
		id: 'myciAuth.signInUserField_username',
		defaultMessage: 'Username',
		description: 'Field name of security entity',
	},
	signInForgotPassword: {
		id: 'myciAuth.signInForgotPassword',
		defaultMessage: 'Forgot your password?',
		description: 'Caption for forgotten password',
	},
	signInHeading: {
		id: 'myciAuth.signInHeading',
		defaultMessage: 'Registration',
		description: 'Heading for SignIn page',
	},
	signInRegister: {
		id: 'myciAuth.signInRegister',
		defaultMessage: "Don't have an account yet?",
		description: 'Caption for registration link',
	},
	resetButton: {
		id: 'myciAuth.resetButton',
		defaultMessage: 'Reset password',
		description: 'Label of the reset password button',
	},
	changeButton: {
		id: 'myciAuth.changeButton',
		defaultMessage: 'Change password',
		description: 'Label of the change password button',
	},
	passwordLabel: {
		id: 'myciAuth.passwordLabel',
		defaultMessage: 'Password',
		description: 'Label of the password field',
	},
	confirmPasswordLabel: {
		id: 'myciAuth.confirmPasswordLabel',
		defaultMessage: 'Confirm password',
		description: 'Label of the confirm password field',
	},
	logInSubmit: {
		id: 'myciAuth.logInSubmit',
		defaultMessage: 'Login',
		description: 'Label of the submit button',
	},
});
