import PropTypes from 'prop-types';
import { Link } from '@myci/gatsby-plugin-intl';
import React, { memo, useEffect } from 'react';
import { isNil, o } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { Box, CircularLoader, Grid, Heading, Section, Text } from '@myci/ui-components';
import { withReducers } from 'redux-syringe';
import {
	reducer as authentication,
	fetchResetPasswordToken,
	selectIsFetchingResetPasswordToken,
	selectResetPasswordTokenEntity,
} from '@myci/authentication';
import { Message } from '@myci/intl';
import { InstanceTypes, currentInstanceType } from '@myci/utils';
import { ChangePasswordForm } from '@myci/module-authentication';
import { useDispatch, useSelector } from 'react-redux';

import m from '../messages';
import PublicLayout from '../layouts/PublicLayout';

const ChangePassword = ({ token }) => {
	const dispatch = useDispatch();

	const resetPasswordTokenEntity = useSelector(selectResetPasswordTokenEntity(token));
	const isFetchingResetPasswordToken = useSelector(selectIsFetchingResetPasswordToken);

	const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

	useEffect(() => {
		if (isNotNil(token) && isNil(resetPasswordTokenEntity)) {
			dispatch(fetchResetPasswordToken({ token }));
		}
	}, [token, resetPasswordTokenEntity]);

	const getResetPasswordTokenValidity = () => {
		if (isFetchingResetPasswordToken) {
			return <CircularLoader />;
		}
		if (isNotNil(token) && isNotNil(resetPasswordTokenEntity)) {
			const { success } = resetPasswordTokenEntity;

			return (
				<Grid row alignItems="center" className="text-center">
					<Grid col={12}>
						{success ? (
							<>
								<Text mb={4}>{resetPasswordTokenEntity.messageLocalized}</Text>
								<Box mt={1}>
									<Link to="/">
										<Message {...m.goHome} />
									</Link>
								</Box>
							</>
						) : (
							<>
								{resetPasswordTokenEntity?.errors.map(error => (
									<Text mb={4}>{error.message}</Text>
								))}
								<Box mt={1}>
									<Link to="/forgotten-password">
										<Message {...m.goResetPassword} />
									</Link>
								</Box>
							</>
						)}
					</Grid>
				</Grid>
			);
		}
	};

	return (
		<PublicLayout
			header={
				<Section pb={{ xs: 4, lg: 0 }} pt={4}>
					<Grid container>
						<Grid row>
							<Grid col={{ xs: 12, md: 6, lg: 5 }}>
								<Heading as="h1" mb={{ xs: 3, md: 4 }}>
									<Message {...m.changePasswordAction} />
								</Heading>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			}
		>
			{isBoInstance ? getResetPasswordTokenValidity() : <ChangePasswordForm token={token} />}
		</PublicLayout>
	);
};

ChangePassword.propTypes = {
	token: PropTypes.string,
};

export default o(withReducers({ authentication }, { isGlobal: true }), memo)(ChangePassword);
