import PropTypes from 'prop-types';
import React from 'react';
import { Button, Grid } from '@myci/ui-components';
import { Form, reduxForm } from '@myci/ui-components-redux';
import { Message } from '@myci/intl';
import { Link } from '@myci/gatsby-plugin-intl';
import { alwaysNull } from 'ramda-extension';
import { changePassword, selectIsChangingPassword } from '@myci/authentication';
import { mergeDeepWithKey } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { formPropTypes } from 'redux-form';
import { isRequired } from '@validarium/validations';
import { validate as validariumValidate } from '@validarium/core';
import {
	PasswordInputField,
	makeValidatePasswordAsync,
	validatePasswordsConfirm,
} from '@myci/password';

import m from '../messages';
import { forms } from '../constants';

const form = forms.changePassword;

const validate = data => {
	const validariumValidations = validariumValidate({
		password: [isRequired],
		confirmPassword: [isRequired],
	})(data);

	const passwordMatchValidation = validatePasswordsConfirm({
		password: data.password,
		confirmPassword: data.confirmPassword,
	});

	return mergeDeepWithKey(alwaysNull, validariumValidations, passwordMatchValidation);
};

const PASSWORD_FIELD_NAME = 'password';
const asyncBlurFields = [PASSWORD_FIELD_NAME];

const ChangePasswordForm = ({ handleSubmit, token }) => {
	const isSubmitting = useSelector(selectIsChangingPassword);
	const dispatch = useDispatch();

	return (
		<Form
			onSubmit={handleSubmit(values => {
				dispatch(changePassword({ ...values, token, form }));
			})}
		>
			<Grid container flexDirection="column" justifyContent="between">
				<Grid row>
					<Grid col={{ xs: 12, md: 6, lg: 5 }}>
						<Grid row mb={3}>
							<Grid col={{ xs: 12, md: 9 }}>
								<PasswordInputField
									name={PASSWORD_FIELD_NAME}
									label={<Message {...m.passwordLabel} />}
									isDisabled={isSubmitting}
									isRequired
									hasRulesPopover
								/>
							</Grid>
						</Grid>
						<Grid row mb={3}>
							<Grid col={{ xs: 12, md: 9 }}>
								<PasswordInputField
									name="confirmPassword"
									label={<Message {...m.confirmPasswordLabel} />}
									isDisabled={isSubmitting}
									isRequired
								/>
							</Grid>
						</Grid>
						<Grid container flexDirection="row" mt={4} p={0} justifyContent="start">
							<Link to="/sign-in">
								<Button>
									<Message {...m.cancelButton} />
								</Button>
							</Link>
							<Button kind="primary" type="submit" disabled={isSubmitting}>
								<Message {...m.changeButton} />
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	);
};

ChangePasswordForm.propTypes = {
	...formPropTypes,
	changePassword: PropTypes.func,
};

export default reduxForm({
	validate,
	form,
	asyncBlurFields,
	asyncValidate: makeValidatePasswordAsync(PASSWORD_FIELD_NAME),
})(ChangePasswordForm);
