import { useCallback, useState } from 'react';
import { Button, Tooltip } from '@ci/atoms';
import { Message } from '@ci/message';
import { useWatch } from 'react-hook-form';

import { m } from '../../messages';
import { TextInputField, TextInputFieldProps } from './TextInputField';
import { useCurrentForm } from '../../hooks/useCurrentForm';
import { FormInstance } from '../../types';

type PasswordInputFieldProps = Omit<TextInputFieldProps, 'addon' | 'type'>;

export const PasswordInputField = (props: PasswordInputFieldProps) => {
	const form: FormInstance<any> = useCurrentForm();
	const { name } = props;

	const password = useWatch({
		control: form.control,
		name,
	});

	const [isPasswordHidden, setIsPasswordHidden] = useState(true);

	const handleShowPasswordClick = useCallback(() => {
		setIsPasswordHidden(false);
	}, []);

	const handleHidePasswordClick = useCallback(() => {
		setIsPasswordHidden(true);
	}, []);

	const getAddon = () => {
		if (!password) {
			return null;
		}

		return isPasswordHidden ? (
			<Tooltip tooltip={<Message {...m.showPassword} />}>
				<Button
					icon="passwordShow"
					iconProps={{ size: 'md' }}
					onClick={handleShowPasswordClick}
					size="small"
					variant="subtle"
				/>
			</Tooltip>
		) : (
			<Tooltip tooltip={<Message {...m.hidePassword} />}>
				<Button
					icon="passwordHide"
					iconProps={{ size: 'md' }}
					onClick={handleHidePasswordClick}
					size="small"
					variant="subtle"
				/>
			</Tooltip>
		);
	};

	return (
		<TextInputField addon={getAddon()} type={isPasswordHidden ? 'password' : 'text'} {...props} />
	);
};
